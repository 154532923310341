import { reactive } from "vue";

interface UseCodePosition {
  top: string | number;
  right: string | number;
  bottom: string | number;
  left: string | number;
}

const position = reactive<UseCodePosition>({
  top: "auto",
  right: "auto",
  bottom: "0.1rem",
  left: "0.2rem",
});

export function useUseCodePosition() {
  return {
    set({ top, right, bottom, left }: Partial<UseCodePosition>) {
      if (top) position.top = top;
      if (right) position.right = right;
      if (bottom) position.bottom = bottom;
      if (left) position.left = left;
      return this.position;
    },

    get position() {
      return position;
    },
  };
}
