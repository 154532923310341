import { random, sampleSize } from "lodash";

export function allergens(min?: number, max?: number) {
  const mMin = min || 2;
  const mMax = max || 7;

  const allergens = [
    "gluten",
    "crustacés",
    "œufs",
    "poissons",
    "arachides",
    "soja",
    "lait",
    "fruits à coques",
    "céleri",
    "moutarde",
    "sésame",
    "anhyd. sulf. & sulfites",
    "lupin",
    "mollusques",
  ];

  const size = random(mMin, mMax);
  return sampleSize(allergens, size);
}
