<template>
  <div class="dish">
    <div class="dish__top">
      <div class="dish__name" :style="nameStyle">{{ name }}</div>
      <div class="dish__info" :style="infoStyle" v-if="showInfo">
        {{ info }}
      </div>
      <div
        class="dish__allergens"
        :style="allergensStyle"
        v-if="allergens.length"
      >
        <div class="dish__allergen" v-for="(allergen, i) in allergens" :key="i">
          {{ allergen }}
        </div>
      </div>
    </div>
    <div class="dish__bottom">
      <div class="dish__nutriscore" :style="nutriscoreStyle"></div>
      <div class="dish__price" v-if="price">{{ formatPrice(price) }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { CSSProperties } from "vue";
import { round, random, sample } from "lodash";
import BaseTemplate from "../BaseTemplate";
import { Dish116_640x960_Payload } from "./Dish116_640x960.payload";
import { text } from "@/mocks/text.mock";
import { allergens } from "@/mocks/allergens.mock";

export default class Dish116_640x960 extends BaseTemplate<Dish116_640x960_Payload> {
  get name() {
    if (this.payload.name) return this.payload.name;
    else if (this.isDev) return text(2, 6);
    return "";
  }

  get nameStyle(): CSSProperties {
    let size = (7.5 - 0.025 * this.name.length) * (this.showPrice ? 1 : 1.1);
    if (this.showInfo) size *= 0.8;
    return {
      fontSize: `${size}rem`,
      // lineHeight: `${size}rem`,
    };
  }

  get info() {
    if (this.payload.info) return this.payload.info;
    else if (this.isDev && Math.random() > 0.4) return text(2, 7);
    return "";
  }

  get showInfo() {
    return Boolean(this.info.length);
  }

  get infoStyle(): CSSProperties {
    let size = (4 - 0.02 * this.info.length) * (this.showPrice ? 1 : 1.1);
    return {
      "font-size": `${size}rem`,
    };
  }

  get price() {
    if (!this.payload.price && this.isDev) return round(random(1, 9, true), 2);
    return this.payload.price;
  }

  get priceStyle(): CSSProperties {
    let factor = 1;
    if (this.showInfo) factor = 0.8;
    return {
      fontSize: `${factor * 3.4}rem`,
    };
  }

  get showPrice() {
    return Boolean(this.price);
  }

  get allergens() {
    if (this.payload.allergens) return this.payload.allergens;
    if (this.isDev && Math.random() > 0.4) return allergens(2, 9);
    return [];
  }

  get allergensStyle(): CSSProperties {
    const len = this.allergens.reduce<number>((n, al) => n + al.length, 0);
    let size = 2.8 - 0.02 * Math.max(len - 30, 0);
    if (this.showInfo) size *= 0.9;
    if (this.name.length >= 20) size *= 0.9;
    // if (this.name.length >= 30) size *= 0.9;

    return {
      fontSize: `${size}rem`,
    };
  }

  get nutriscore() {
    if (this.payload.nutriscore) return this.payload.nutriscore;
    const scores = ["a", "b", "c", "d", "e"];
    if (this.isDev && Math.random() > 0.5) return sample(scores) as string;
    return undefined;
  }

  get nutriscoreStyle(): CSSProperties {
    if (!this.nutriscore) return {};
    return {
      backgroundImage: `url(http://impactiv.s3.fr-par.scw.cloud/impactiv/nutriscore/nb/nutriscore_${this.nutriscore}.webp)`,
    };
  }
}
</script>

<style scoped lang="scss">
.dish {
  width: 640px;
  height: 960px;

  font-family: Poppins, sans-serif;
  font-size: 12px;

  display: flex;
  flex-direction: column;

  &__top {
    flex: 0 0 80%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  &__bottom {
    position: relative;
    flex: 1 0 0;
  }

  &__name {
    margin: 1.5rem;
    font-weight: bold;
    line-height: 120%;
  }

  &__info {
    margin: 1.5rem;
  }

  &__allergens {
    margin: 1.5rem;
  }

  &__allergen {
    display: inline-block;
    padding: 0.1rem 0.4rem;
    margin: 0.5rem;
    border: 2px solid black;
  }

  &__nutriscore {
    width: 35%;
    height: 70%;
    position: absolute;
    left: 2rem;

    background-repeat: no-repeat;
    background-size: contain;
  }

  &__price {
    right: 0;
    bottom: 0;
    padding: 0.7rem 1.7rem;
    position: absolute;
    font-weight: bold;
    text-align: center;
    color: white;
    font-size: 3.75rem;
    background-color: black;
  }
}
</style>
