import { Config } from "@/model/config";
import { useRoute } from "vue-router";
import { Payload } from "./default.payload";

function formatPrice(price: number | string, symbol = "€") {
  let numberStr = "";

  // Si c'est une string, on la convertit en nombre pour pouvoir la formater
  if (typeof price === "string") price = parseFloat(price);
  // NaN ?
  if (isNaN(price)) price = 0;

  // Si c'est une monnaie, on formate en 2 décimales
  if (["€"].includes(symbol)) {
    numberStr = price.toFixed(2);
  } else numberStr = price.toFixed(0);

  // On remplace le point par une virgule
  numberStr = numberStr.replace(".", ",");

  // On ajoute le symbole derrière
  numberStr += symbol;

  return numberStr;
}

export function useBaseTemplate<T extends Payload>() {
  const route = useRoute();

  return {
    get isDev() {
      return process.env.NODE_ENV === "development";
    },

    get config(): Config {
      let json = route.query.t?.toString();
      if (!json) json = window.localStorage.getItem("mock") || "{}";

      const config = JSON.parse(json) as Config<T>;

      if (!config.template)
        config.template =
          route.query.template?.toString() || "SimpleDish29_384x168";

      return config;
    },

    get payload(): T {
      return this.config.payload || {};
    },

    formatPrice,
  };
}
