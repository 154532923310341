<template>
  <div class="qr">
    <img v-if="imgSrc" :src="imgSrc" />
  </div>
</template>

<script lang="ts">
import BaseTemplate from "../BaseTemplate";
import { QR_384x168_Payload } from "./QR_384x168.payload";
import qrcode from "qrcode";
import { WatchStopHandle } from "@vue/runtime-core";

export default class QR_384x168 extends BaseTemplate<QR_384x168_Payload> {
  mounted() {
    this.genQRCode();
    this.unwatch = this.$watch("labelCode", () => {
      this.genQRCode();
    });
  }

  beforeUnmount() {
    if (this.unwatch) this.unwatch();
  }

  get labelCode() {
    return this.payload.labelCode;
  }

  async genQRCode() {
    //
    this.imgSrc = await qrcode.toDataURL(this.labelCode, {
      margin: 0,
      width: 250,
    });
  }

  private imgSrc = "IMG";
  private unwatch!: WatchStopHandle;
}
</script>

<style lang="scss" scoped>
.qr {
  width: 384px;
  height: 168px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  img {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    height: 148px;
    width: 148px;
    background-color: black;
  }
}
</style>