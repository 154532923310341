<template>
  <div class="home">
    <div class="home__wrapper">
      <component :is="components[component]" class="template"></component>
    </div>
    <div
      class="home__use_code"
      :style="{
        fontSize: useCode.size,
        left: useCode.left,
        right: useCode.right,
        top: useCode.top,
        bottom: useCode.bottom,
      }"
      v-if="useCode.text"
      v-text="useCode.text"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { useBaseTemplate } from "@/templates/BaseTemplate.composition";
import Dish116_640x960 from "@/templates/Dish116_640x960/Dish116_640x960.vue";
import Dish29M2_296x128 from "@/templates/Dish29M2_296x128/Dish29M2_296x128.vue";
import Dishes116_640x960 from "@/templates/Dishes116_640x960/Dishes116_640x960.vue";
import Dishes75_384x640 from "@/templates/Dishes75_384x640/Dishes75_384x640.vue";
import Dishes75_480x800 from "@/templates/Dishes75_480x800/Dishes75_480x800.vue";
import QR_384x168 from "@/templates/QR_384x168/QR_384x168_384x168.vue";
import SimpleDish29_384x168 from "@/templates/SimpleDish29_384x168/SimpleDish29_384x168.vue";
import SimpleDish42_400x300 from "@/templates/SimpleDish42_400x300/SimpleDish42_400x300.vue";
import SimpleDish4d2 from "@/templates/SimpleDish4d3/SimpleDish4d2.vue";
import SimpleSquare_100x100 from "@/templates/SimpleSquare_100x100/SimpleSquare_100x100.vue";
import { computed } from "vue";
import { useUseCodePosition } from "./use-code-position.composable";

// Cette variable n'est pas utilisée mais évite d'avoir un warning "import not used"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const components: { [key: string]: unknown } = {
  Dish116_640x960,
  Dish29M2_296x128,
  Dishes116_640x960,
  Dishes75_384x640,
  Dishes75_480x800,
  QR_384x168,
  SimpleDish29_384x168,
  SimpleDish42_400x300,
  SimpleDish4d2,
  SimpleSquare_100x100,
};

const base = useBaseTemplate();

const component = computed(() => {
  return base.config.template;
});

const useCodePosition = useUseCodePosition();

const useCode = computed(() => {
  return {
    text: base.payload.useCode || "A01",
    size: `${base.payload.useCodeSize}rem`,
    ...useCodePosition.position,
  };
});
</script>

<style lang="scss" scoped>
@import "~@fontsource/open-sans/400.css";
@import "~@fontsource/open-sans/800.css";
@import "~@fontsource/poppins/index.css";

@font-face {
  font-family: "Pixel Square";
  src: url("~@/templates/SimpleDish29_384x168/fonts/PixelSquare10.ttf")
    format("truetype");
}

.home {
  position: absolute;
  width: 100%;
  height: 100%;

  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    border: solid black;
    border-width: 0 0px 0px 0;
  }

  &__use_code {
    position: absolute;
    left: 0.15rem;
    bottom: 0.1rem;

    font-size: 1.15rem;
    font-weight: bold;
    font-family: "Pixel Square", sans-serif;
  }
}

.template {
  background-color: white;
}
</style>
