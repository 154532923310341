<template>
  <div class="dish42">
    <div class="dish42__top" :style="topStyle">
      <div class="dish42__name" :style="nameStyle">
        {{ name }}
      </div>
      <div class="dish42__info" :style="infoStyle" v-if="infoShouldBeDisplayed">
        {{ info }}
      </div>
    </div>
    <div class="dish42__bottom">
      <div
        class="dish42__price"
        :style="{}"
        v-if="priceShouldBeDisplayed && price"
      >
        {{ base.formatPrice(price, priceSymbol) }}
      </div>
      <div
        class="dish42__nutriscore"
        v-if="nutriscore"
        :style="nutriscoreStyle"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { text } from "@/mocks/text.mock";
import { useUseCodePosition } from "@/views/home/use-code-position.composable";
import { random, round } from "lodash";
import { StyleValue, computed } from "vue";
import { nutriscore as nustriscoreMock } from "../../mocks/nutriscore.mock";
import { useBaseTemplate } from "../BaseTemplate.composition";
import { Payload } from "../default.payload";

type LocalPayload = {
  name: string;
  info: string;
  price: number;
  nutriscore?: string;
  priceSymbol: string;
} & Payload;

const base = useBaseTemplate<LocalPayload>();

const name = computed(() => {
  if (base.isDev) return text(3, 6);
  return base.payload.name;
});

const info = computed(() => {
  if (base.isDev)
    return Math.random() > 0.4
      ? "Saisie supplémentaire sur deux lignes maximum"
      : "";
  return base.payload.info || "";
});

const price = computed(() => {
  if (base.isDev)
    return Math.random() > 0.1 ? round(random(1, 3, true), 2) : undefined;
  return base.payload.price;
});

const nutriscore = computed(() => {
  if (base.isDev) return Math.random() > 0.2 ? nustriscoreMock() : undefined;
  return base.payload.nutriscore;
});

const priceSymbol = computed(() => {
  if (base.isDev) return Math.random() > 0.5 ? "€" : " pts";
  return base.payload.priceSymbol;
});

const priceShouldBeDisplayed = computed(() => {
  return price.value !== undefined;
});

const infoShouldBeDisplayed = computed(() => {
  return info.value.length > 0;
});

const topStyle = computed<StyleValue>(() => {
  return {
    justifyContent: infoShouldBeDisplayed.value ? "flex-start" : "center",
  };
});

const nameFontSizeRem = computed(() => {
  let size = Math.max(
    (4.8 - 0.035 * name.value.length) *
      (priceShouldBeDisplayed.value ? 1 : 1.1),
    1.8
  );
  if (infoShouldBeDisplayed.value) size *= 0.8;
  return size;
});

const nameStyle = computed<StyleValue>(() => {
  return {
    fontSize: `${nameFontSizeRem.value}rem`,
    height: `${info.value ? 2.1 : 3.1}lh`,
    // marginBottom: `${nameFontSizeRem.value / 3}rem`,
  };
});

const infoFontSizeRem = computed(() => {
  return nameFontSizeRem.value * 0.68;
});

const infoStyle = computed<StyleValue>(() => {
  return {
    fontSize: `${infoFontSizeRem.value}rem`,
    // marginBottom: `${nameFontSizeRem.value / 2.5}rem`,
  };
});

const priceStyle = computed<StyleValue>(() => {
  return {
    fontSize: `${nameFontSizeRem.value / 1.3}rem`,
    padding: `${nameFontSizeRem.value / 7.5}rem ${
      nameFontSizeRem.value / 2.6
    }rem`,
  };
});

const nutriscoreStyle = computed<StyleValue>(() => {
  if (nutriscore.value)
    return {
      backgroundImage: `url(https://impactiv.s3.fr-par.scw.cloud/impactiv/nutriscore/nb/nutriscore_${nutriscore.value}.webp)`,
    };
  return {};
});

const useCodePosition = useUseCodePosition();
useCodePosition.set({
  top: "0.4rem",
  right: "0.15rem",
  bottom: "auto",
  left: "auto",
});
</script>

<style scoped lang="scss">
.dish42 {
  width: 100vw;
  height: 100vh;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;

  font-family: "Open Sans", sans-serif;

  &__top {
    flex: 1 0 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
  }

  &__name {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    overflow: hidden;

    height: 2.1lh;
    overflow: hidden;
    line-height: 110%;

    font-size: 1.5rem;
    font-weight: 800;
    text-overflow: ellipsis;
    font-family: "Open Sans", sans-serif;
  }

  &__info {
    height: 2lh;

    font-size: 1.5rem;
    overflow: hidden;
  }

  &__bottom {
    display: flex;
    flex-basis: 20%;
  }

  &__price {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.1rem;
    min-width: 10rem;
    margin-right: 1.8rem;

    font-size: 2.4rem;
    font-weight: bold;
    color: white;
    white-space: nowrap;

    background-color: black;
  }

  &__nutriscore {
    aspect-ratio: 305 / 166;
    height: 100%;

    // background-image: url("https://impactiv.s3.fr-par.scw.cloud/impactiv/nutriscore/nb/nutriscore_b.webp");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
</style>
