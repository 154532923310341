import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d327f3b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dishes" }
const _hoisted_2 = { class: "dishes__debug" }
const _hoisted_3 = { class: "dishes__list" }
const _hoisted_4 = { class: "dish__name" }
const _hoisted_5 = {
  key: 0,
  class: "dish__price"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.dishes.length) + " plats", 1),
    (_ctx.header)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "dishes__header",
          style: _normalizeStyle(_ctx.dishBaseStyle)
        }, _toDisplayString(_ctx.header), 5))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dishes, (dish, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "dishes__dish dish",
          key: i,
          style: _normalizeStyle(_ctx.dishBaseStyle)
        }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(dish.name), 1),
          (dish.price)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.formatPrice(dish.price)), 1))
            : _createCommentVNode("", true)
        ], 4))
      }), 128))
    ])
  ]))
}