<template>
  <div class="dish29">
    <div class="dish29__name" :style="nameStyle">{{ name }}</div>
    <div class="dish29__bottom">
      <div
        class="dish29__nutriscore"
        v-if="nutriscore"
        :style="nutriscoreStyle"
      ></div>
      <div class="dish29__price" v-if="showPrice">
        {{ formatPrice(price, priceSymbol) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { nutriscore as nutriscoreMock } from "@/mocks/nutriscore.mock";
import { text } from "@/mocks/text.mock";
import { random } from "lodash";
import { StyleValue } from "vue";
import BaseTemplate from "../BaseTemplate";
import { SimpleDish29_384x168_Payload } from "./SimpleDish29_384x168.payload";

export default class SimpleDish29_384x168 extends BaseTemplate<SimpleDish29_384x168_Payload> {
  get name() {
    if (this.isDev) return this.payload.name || text(3, 6);
    return this.payload.name || "";
  }

  get nameStyle() {
    const size = (3 - 0.02 * this.name.length) * (this.showPrice ? 1 : 1.1);
    return {
      "font-size": `${size}rem`,
    };
  }

  get price() {
    if (this.isDev) return random(1, 3, true);
    return this.payload.price;
  }

  get nutriscore() {
    if (this.isDev) return Math.random() > 0.2 ? nutriscoreMock() : undefined;
    return this.payload.nutriscore;
  }

  get showPrice() {
    return Boolean(this.price);
  }

  get priceSymbol() {
    if (this.isDev) return Math.random() > 0.5 ? "€" : " pts";
    return this.payload.priceSymbol;
  }

  get nutriscoreStyle(): StyleValue {
    if (this.nutriscore)
      return {
        backgroundImage: `url(https://impactiv.s3.fr-par.scw.cloud/impactiv/nutriscore/nb/nutriscore_${this.nutriscore}.webp)`,
      };
    return {};
  }
}
</script>

<style scoped lang="scss">
.dish29 {
  width: 384px;
  height: 168px;

  display: flex;
  flex-direction: column;

  &__name {
    flex: 1 0 0;
    padding: 0.8rem;

    display: flex;
    align-items: center;

    font-size: 1.5rem;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    overflow: hidden;
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-basis: 3.8rem;
  }

  &__price {
    display: flex;
    align-items: center;
    padding: 0 0.6rem;

    font-size: 2.6rem;
    font-weight: bold;
    color: white;
    white-space: nowrap;

    background-color: black;
  }

  &__nutriscore {
    height: 100%;
    aspect-ratio: 305 / 166;
    margin-right: 1.5rem;

    background-position: center top;
    background-size: 100% 98%;
    background-repeat: no-repeat;
  }
}
</style>
