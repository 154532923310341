<template>
  <div class="square">
    {{ name }}<br /><br />
    <small>Simple Square</small>
  </div>
</template>

<script lang="ts">
import BaseTemplate from "../BaseTemplate";
import { SimpleSquare_100x100_Payload } from "./SimpleSquare_100x100.payload";

export default class SimpleSquare_100x100 extends BaseTemplate<SimpleSquare_100x100_Payload> {
  get name() {
    return this.payload.name;
  }
}
</script>

<style scoped>
.square {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  text-align: center;
  font-size: 1rem;
}
</style>