import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ada422bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dish" }
const _hoisted_2 = { class: "dish__top" }
const _hoisted_3 = { class: "dish__bottom" }
const _hoisted_4 = {
  key: 0,
  class: "dish__price"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "dish__name",
        style: _normalizeStyle(_ctx.nameStyle)
      }, _toDisplayString(_ctx.name), 5),
      (_ctx.showInfo)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "dish__info",
            style: _normalizeStyle(_ctx.infoStyle)
          }, _toDisplayString(_ctx.info), 5))
        : _createCommentVNode("", true),
      (_ctx.allergens.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "dish__allergens",
            style: _normalizeStyle(_ctx.allergensStyle)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allergens, (allergen, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "dish__allergen",
                key: i
              }, _toDisplayString(allergen), 1))
            }), 128))
          ], 4))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "dish__nutriscore",
        style: _normalizeStyle(_ctx.nutriscoreStyle)
      }, null, 4),
      (_ctx.price)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.formatPrice(_ctx.price)), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}