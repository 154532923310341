<template>
  <router-view />
</template>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;

  color: black;
  background-color: white;
  font-family: sans-serif;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}
</style>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class App extends Vue {}
</script>
