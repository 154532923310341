<template>
  <div class="dish">
    <div class="dish__top">
      <div class="dish__name" :style="nameStyle">{{ name }}</div>
      <div class="dish__info" :style="infoStyle">{{ info }}</div>
    </div>
    <div class="dish__bottom">
      <div class="dish__price" v-if="price">{{ formatPrice(price) }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { text } from "@/mocks/text.mock";
import { random, round } from "lodash";
import { CSSProperties } from "vue";
import BaseTemplate from "../BaseTemplate";
import { Dish29M2_296x128_Payload } from "./Dish29M2_296x128.payload";

export default class Dish29M2_296x128 extends BaseTemplate<Dish29M2_296x128_Payload> {
  get name() {
    if (this.isDev) return text(2, 4);
    return this.payload.name || "";
  }

  get nameStyle(): CSSProperties {
    let fontSize = 28 - this.name.length * 0.35;
    if (!this.payload.info) {
      fontSize *= 1.4;
    }
    return {
      fontSize: `${fontSize}px`,
      lineHeight: `${fontSize}px`,
    };
  }

  get info() {
    if (this.isDev) return Math.random() > 0.4 ? text(2, 6) : "";
    return this.payload.info || "";
  }

  get infoStyle(): CSSProperties {
    if (!this.info) return {};
    let fontSize = 20 - this.info.length * 0.1;
    return {
      fontSize: `${fontSize}px`,
      lineHeight: `${fontSize}px`,
      marginTop: `${fontSize / 2}px`,
    };
  }

  get price() {
    if (this.isDev) return round(random(1, 6), 2);
    return this.payload.price;
  }
}
</script>

<style scoped lang="scss">
.dish {
  width: 296px;
  height: 128px;

  font-family: Poppins, "Open Sans", sans-serif;
  font-size: 12px;

  display: flex;
  flex-direction: column;

  &__top {
    display: flex;
    flex-direction: column;
    flex: 0 0 65%;
    overflow: hidden;
    padding: 0.5rem;

    justify-content: center;
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    flex: 1 0 0;
  }

  &__info {
    font-size: 1.2rem;
  }

  &__price {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 4rem;

    color: white;
    font-size: 1.2rem;

    background-color: black;
  }
}
</style>
