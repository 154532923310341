import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-385fed26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dish29" }
const _hoisted_2 = { class: "dish29__bottom" }
const _hoisted_3 = {
  key: 1,
  class: "dish29__price"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "dish29__name",
      style: _normalizeStyle(_ctx.nameStyle)
    }, _toDisplayString(_ctx.name), 5),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.nutriscore)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "dish29__nutriscore",
            style: _normalizeStyle(_ctx.nutriscoreStyle)
          }, null, 4))
        : _createCommentVNode("", true),
      (_ctx.showPrice)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.formatPrice(_ctx.price, _ctx.priceSymbol)), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}