<template>
  <div class="dishes">
    <div class="dishes__debug">{{ dishes.length }} plats</div>
    <div class="dishes__header" v-if="header" :style="dishBaseStyle">
      {{ header }}
    </div>
    <div class="dishes__list">
      <div
        class="dishes__dish dish"
        v-for="(dish, i) in dishes"
        :key="i"
        :style="dishBaseStyle"
      >
        <div class="dish__name">{{ dish.name }}</div>
        <div class="dish__price" v-if="dish.price">
          {{ formatPrice(dish.price) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { text } from "@/mocks/text.mock";
import { random, round } from "lodash";
import { CSSProperties } from "vue";
import BaseTemplate from "../BaseTemplate";
import { Dishes75_384x640_Payload } from "./Dishes75_384x640.payload";

export default class Dishes75_384x640 extends BaseTemplate<Dishes75_384x640_Payload> {
  mounted() {
    //
  }

  get header() {
    if (this.isDev) return Math.random() > 0.4 ? text(3, 3) : "";
    return this.payload.header || "";
  }

  get dishes() {
    if (this.isDev) {
      const n = random(0, 5);
      const dishes = [];
      for (let i = 0; i < n + 1; i++) {
        dishes.push({
          name: text(2, 5),
          price: round(random(1, 6, true), 2),
        });
      }
      return dishes;
    }
    return this.payload.dishes || [];
  }

  get dishBaseStyle(): CSSProperties {
    let size = 320 - Math.max(0, this.dishesNamesTotalLength - 3) / 1.2;
    if (this.header) size *= 0.97;
    return {
      fontSize: `${size}%`,
    };
  }

  get dishesNamesTotalLength() {
    let len = 0;
    for (const dish of this.dishes) len += (dish.name || "").length;
    return len;
  }
}
</script>

<style scoped lang="scss">
.dishes {
  position: relative;
  width: 384px;
  height: 640px;

  font-family: Poppins, sans-serif;
  font-size: 12px;

  display: flex;
  flex-direction: column;

  &__header {
    padding: 0.5em 0;
    text-align: center;
    font-weight: bold;
    font-size: 190%;
    text-transform: uppercase;
    color: white;
    background-color: black;
  }

  &__list {
    flex-grow: 1;
    padding: 1em;
    padding-bottom: 2em;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  &__debug {
    display: none;
    padding: 0.3rem;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    color: white;
    background-color: black;
  }
}

.dish {
  display: flex;
  flex-direction: row;
  font-size: 180%;
  font-weight: 600;

  border-bottom: 1px solid black;

  &__name {
    padding-bottom: 0.3em;
    padding-right: 1.5em;
    flex-grow: 1;
  }
}
</style>
