import { Config } from "@/model/config";
import { Vue } from "vue-class-component";

export default class Base extends Vue {
  get config(): Config {
    let json = this.$route.query.t?.toString();
    if (!json) json = window.localStorage.getItem("mock") || "{}";

    const config = JSON.parse(json) as Config;

    if (!config.template)
      config.template =
        this.$route.query.template?.toString() || "SimpleDish29_384x168";
    if (!config.payload) config.payload = {};

    return config;
  }
}
