import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-793481e7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dish42" }
const _hoisted_2 = { class: "dish42__top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "dish42__name",
        style: _normalizeStyle(_ctx.nameStyle)
      }, _toDisplayString(_ctx.name), 5),
      (_ctx.showInfo)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "dish42__info",
            style: _normalizeStyle(_ctx.infoStyle)
          }, _toDisplayString(_ctx.info), 5))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showPrice)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "dish42__price",
          style: _normalizeStyle(_ctx.priceStyle)
        }, _toDisplayString(_ctx.formatPrice(_ctx.price, _ctx.priceSymbol)), 5))
      : _createCommentVNode("", true)
  ]))
}