<template>
  <div class="dishes57">
    <div class="dishes57__header" :style="dishBaseStyle" v-if="header">
      {{ header }}
    </div>
    <div class="dishes57__dishes">
      <div
        class="dishes57__dish dish"
        v-for="(dish, i) in dishes"
        :key="i"
        :style="dishBaseStyle"
      >
        <div class="dish__name">{{ dish.name }}</div>
        <div class="dish__price" v-if="dish.price">
          {{ formatPrice(dish.price) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { random, range, round } from "lodash";
import { CSSProperties } from "vue";
import BaseTemplate from "../BaseTemplate";
import { Dishes75_480x800_Payload } from "./Dishes75_480x800.payload";
import { Dish } from "@/model/template-data/dish.data";
import { text } from "@/mocks/text.mock";

const config = {
  maxDishes: 12,
};

export default class Dishes75_480x800 extends BaseTemplate<Dishes75_480x800_Payload> {
  mounted() {
    //
  }

  get header() {
    if (this.isDev) return Math.random() > 0.5 ? text(3, random(5, 7)) : "";
    return this.payload.header;
  }

  get dishes() {
    if (this.isDev)
      return range(1, random(12)).map(() => {
        const d: Dish = {
          name: text(2, 6),
          price: round(random(0, 5, true), 2),
        };
        return d;
      });
    if (!this.payload.dishes) return [];
    return this.payload.dishes.slice(0, config.maxDishes - 1);
  }

  get dishBaseStyle(): CSSProperties {
    return {
      fontSize: `${320 - Math.max(0, this.dishesNamesTotalLength - 3) / 1.8}%`,
    };
  }

  get dishesNamesTotalLength() {
    let len = 0;
    for (const dish of this.dishes) len += dish.name.length;
    return len;
  }
}
</script>

<style scoped lang="scss">
.dishes57 {
  width: 480px;
  height: 800px;

  font-family: Poppins, sans-serif;
  font-size: 12px;

  display: flex;
  flex-direction: column;

  &__header {
    padding: 0.7em 0;
    text-align: center;
    font-weight: bold;
    font-size: 190%;
    text-transform: uppercase;
    color: white;
    background-color: black;
  }

  &__dishes {
    flex-grow: 1;
    padding: 1em;
    padding-bottom: 2em;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
}

.dish {
  display: flex;
  flex-direction: row;
  font-size: 180%;
  font-weight: 600;

  border-bottom: 1px solid black;

  &__name {
    padding-bottom: 0.3em;
    padding-right: 1.5em;
    flex-grow: 1;
  }
}
</style>
