<template>
  <div class="dish42">
    <div class="dish42__top">
      <div class="dish42__name" :style="nameStyle">{{ name }}</div>
      <div class="dish42__info" :style="infoStyle" v-if="showInfo">
        {{ info }}
      </div>
    </div>
    <div class="dish42__price" :style="priceStyle" v-if="showPrice">
      {{ formatPrice(price, priceSymbol) }}
    </div>
  </div>
</template>

<script lang="ts">
import { text } from "@/mocks/text.mock";
import { random, round } from "lodash";
import { CSSProperties } from "vue";
import BaseTemplate from "../BaseTemplate";
import { SimpleDish42_400x300_Payload } from "./SimpleDish42_400x300.payload";

export default class SimpleDish42_400x300 extends BaseTemplate<SimpleDish42_400x300_Payload> {
  get name() {
    if (this.isDev) return text(2, 4);
    return this.payload.name;
  }

  get nameStyle(): CSSProperties {
    let size = (4 - 0.02 * this.name.length) * (this.showPrice ? 1 : 1.1);
    if (this.showInfo) size *= 0.8;
    console.log("size=", size);
    return {
      "font-size": `${size}rem`,
    };
  }

  get info() {
    if (this.isDev) return Math.random() > 0.4 ? text(2, 6) : "";
    return this.payload.info || "";
  }

  get showInfo() {
    return Boolean(this.info.length);
  }

  get infoStyle(): CSSProperties {
    let size = (2.5 - 0.02 * this.info.length) * (this.showPrice ? 1 : 1.1);
    return {
      "font-size": `${size}rem`,
    };
  }

  get price() {
    if (this.isDev) return round(random(1, 3), 2);
    return this.payload.price;
  }

  get priceSymbol() {
    if (this.isDev) return Math.random() > 0.5 ? "€" : " pts";
    return this.payload.priceSymbol;
  }

  get priceStyle(): CSSProperties {
    let factor = 1;
    if (this.showInfo) factor = 0.8;
    return {
      fontSize: `${factor * 3.4}rem`,
    };
  }

  get showPrice() {
    return Boolean(this.price);
  }
}
</script>

<style scoped lang="scss">
.dish42 {
  width: 400px;
  height: 300px;

  display: flex;
  flex-direction: column;

  &__top {
    flex: 1 0 70%;
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__name {
    flex: 1 0 0;
    padding: 0.8rem;

    display: flex;
    align-items: center;

    font-size: 1.5rem;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    overflow: hidden;
  }

  &__info {
    flex: 0 0 30%;
    padding: 0.8rem;

    font-size: 1.5rem;
  }

  &__price {
    flex: 0 0 0;
    align-self: flex-end;
    padding: 0.4rem 0.6rem;

    font-size: 3.4rem;
    font-weight: bold;
    color: white;
    background-color: black;
  }
}
</style>
