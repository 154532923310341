import Base from "@/Base";

export default class BaseTemplate<T> extends Base {
  get payload(): T {
    return this.config.payload;
  }

  formatPrice(price: number | string, symbol = "€") {
    let numberStr = "";

    // Si c'est une string, on la convertit en nombre pour pouvoir la formater
    if (typeof price === "string") price = parseFloat(price);
    // NaN ?
    if (isNaN(price)) price = 0;

    // Si c'est une monnaie, on formate en 2 décimales
    if (["€"].includes(symbol)) {
      numberStr = price.toFixed(2);
    } else numberStr = price.toFixed(0);

    // On remplace le point par une virgule
    numberStr = numberStr.replace(".", ",");

    // On ajoute le symbole derrière
    numberStr += symbol;

    return numberStr;
  }

  get isDev() {
    return process.env.NODE_ENV === "development";
  }
}
